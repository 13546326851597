body {
    font-family: Arial, sans-serif;
    background-color: #121212;
    color: #b3b3b3;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
  }
  
  /* .time-selector {
    position: relative;
  } */
  
  #timeButton {
    width: 100%;
    height: 40px;
    outline: none;
    font-size: 14px;
    padding: 0px 22px 0 1rem;
    border: 1.5px solid var(--text-color-secondary);
    background-color: #1A1A1A;
    color: white;
  }
  #timeButtonend {
    width: 100%;
    height: 40px;
    outline: none;
    font-size: 14px;
    padding: 0px 22px 0 1rem;
    border: 1.5px solid var(--text-color-secondary);
    background-color: #1A1A1A;
    color: white;
  }
  .time-selector .divider {
    width: 1px;
    background-color: #c9c0c0;
    margin: 0 10px; /* Adjust margin as needed */
  }
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: #333;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    width: 250px;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #b3b3b3;
    font-size: 24px;
    cursor: pointer;
  }
  
  .time-select {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .scroll-select {
    height: 250px; /* Adjusted height to display at least 5 numbers */
    overflow-y: scroll;
    width: 70px;
    scrollbar-width: none; /* Firefox */
  }
  
  .scroll-select::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  
  .time-option {
    padding: 20px 0; /* Adjust padding for better spacing */
    cursor: pointer;
    color: #b3b3b3;
    font-size: 24px; /* Increased font size */
  }
  
  .time-option.selected {
    color: black;
  }
  
  .time-option.disabled {
    color: #777;
    pointer-events: none;
  }
  
  .popup-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .popup-actions button {
    background-color: #333;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    margin: 0 10px;
  }
  
  .popup-actions button:hover {
    background-color: #555;
  }