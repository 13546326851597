@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* @font-face {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended';
    src: url('../../assets/font/Helvetica_Neue_LT_Pro_63_Medium_Extended.woff') format('woff');
  } */


  .question {
    /* font-family: 'Helvetica Neue LT Pro 63 Medium Extended', 'Prompt', sans-serif; */
    font-weight: 300;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #ccc;
  }
  
  .question-header {
    /* font-family: 'Helvetica Neue LT Pro 63 Medium Extended', 'Prompt', sans-serif; */
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem 0;
  }
  
  .btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.25rem;
    color:white
  }
  
  .content-container {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out, opacity 0.4s ease-out;
    opacity: 0;
  }
  
  .content-container.open {
    max-height: 500px; /* Or any arbitrary max height you want */
    opacity: 1;
    display: flex;
    font-size: smaller;
    color: #666666;
  }
  @media (min-width: 200px) and (max-width: 600px) {
    /* Keep the question header items (title and button) aligned in the same line */
    .question-header {
      font-family: ' Neue LT Pro 63 Medium Extended', 'Prompt', sans-serif;
    font-weight: 300;
      flex-direction: row; /* Ensure row direction */
      justify-content: space-between;
      align-items: center;
    }
  
    /* Adjust title font size for mobile */
    .question-header h6 {
      /* font-family: 'Helvetica Neue LT Pro 63 Medium Extended', 'Prompt', sans-serif; */
    font-weight: 300;
      font-size: 1rem;
      flex: 1; /* Title will take up available space */
    }
  
    /* Ensure the button is still aligned right */
    .btn {
      margin-left: 0.5rem;
      font-size: 1.5rem;
      color: white
    }
  
    /* Collapsible content font size and padding adjustment for mobile */
    .content-container p {
      /* font-family: 'Helvetica Neue LT Pro 63 Medium Extended', 'Prompt', sans-serif; */
    font-weight: 300;
      font-size: 1rem;
      padding: 0;
    }
  
    /* Add padding for mobile view for better spacing */
    .question {
      /* font-family: 'Helvetica Neue LT Pro 63 Medium Extended', 'Prompt', sans-serif; */
    font-weight: 300;
      padding: 1rem 0;
    }
  }