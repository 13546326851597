.subicon {
    display: flex;
    padding-left: 140px  !important
  }
  
  .inputField {
    margin-right: 10px;
    padding: 0 1rem;
    outline: none;
    border: 1px solid;
    font-weight: 10;
    border-radius: 10px;

  }
  
  .subscribeButton {
    width: 50%;
    background-color: #3D3C3C;
    border: 1px solid transparent;
    color: white;
    font-weight: 300;
    height: 40px;
    border-radius: 0.5rem;
    transition: all 0.3s;
  }
  @media screen and (min-width: 767px){
    .subicon {
      display: flex;
      padding-left: 1px !important;
    }
    
    .inputField {
      margin-right: 3px;
      padding: 0 -1rem;
      outline: none;
      border: 1px solid;
      font-weight: 10;
      border-radius: 10px;
    }
    
    .subscribeButton {
      width: 50%;
      background-color: #3D3C3C;
      border: 1px solid transparent;
      color: white;
      font-weight: 300;
      height: 40px;
      border-radius: 0.5rem;
      transition: all 0.3s;

    }
  }