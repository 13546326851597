/* @font-face {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended';
    src: url('../../assets/font/Helvetica_Neue_LT_Pro_63_Medium_Extended.woff') format('woff');
  }
  .personalInfoSection,
  .rightSide,
  .contactus {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended', sans-serif;
  } */
:root {
    --secondary-color: #E6483D;
    --text-color: #EBEBEB;
    --text-color-secondary: #aaaaaa;
}

.contactus{
    font-family: 'Prompt', sans-serif;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
    /* / padding: 0 20vh 0 20vh; / */
}

.contactusElements{
    width: 100%;
    /* / border: 1px solid red; / */
}

.banner {
    width: 100%;
    height: 370px;
    background-image: url('../../assets/images/Group\ 7798.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    margin-bottom: 3rem;
}

.banner span {
    width: 20vw;
    position: absolute;
    top: 5rem;
    left: 5rem;
    font-size: 3rem;
    font-weight: 300;
    /* / border: 1px solid yellow; / */
}

.divide{
    display: flex;
    gap: 3rem;
}

.personalInfoSection{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid white;
    padding: 1rem;
}

.personalInfoSection{
    /* / background-color: var(--secondary-background); / */
    max-width: inherit;
    max-height: fit-content;
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.personalInfoSection input{
    width: 100%;
    height: 30px;
    padding: 0 1rem;
    background-color: black;
    color: gray;
    outline: none;
    border: 1px solid gray;
    font-size: 12px;
}
.personalInfoSection select{
    width: 100%;
    height: 30px;
    padding: 0 1rem;
    background-color: black;
    color: gray;
    outline: none;
    border: 1px solid gray;
    font-size: 12px;
}
.personalInfoSection textarea{
    width: 100%;
    padding: .5rem 1rem;
    background-color: black;
    color: gray;
    outline: none;
    border: 1px solid gray;
    font-size: 12px;
}
.split, .split_division{
    display: flex;
    gap: 2rem;
    font-size: .9rem;
}

.reqDocuments{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.uploadSection{
    border: 1px solid gray;
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    justify-content: space-between;
    padding: .5rem;
}
.uploadSection div{
    display: flex;
    flex-direction: column;
}

input[type="file"] {
    display: none;
}
.fileUpload{
    background-color: var(--secondary-background);
    border: 1px dashed gray;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    padding: 1rem 1.5rem;
}
.paragraphSection{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.rightSide{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid white;
    padding: 2rem;
}
.cInfo{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: .9rem;
}
.cInfo div{
    display: flex;
    flex-direction: column;
    font-size: .9rem;
}
.cInfo div p{
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
    font-size: 12px;
    /* / border: 1px solid var(--secondary-color); / */
    padding: 1rem;
}
.bigText{
    font-size: 1rem;
}
.mediumText{
    font-size: .7rem;
}


/* / For Use Ref messages / */
.refdivision{
    display: flex;
    justify-content: space-between;
}
.tinyText{
    font-size: 10px;
    padding: 0 10px;
}
.secondaryColor{
    color: white;
}

@media screen and (max-width: 320px) {
    .banner{
        height: 160px;
    }
    .banner span{
        font-size: 1rem;
        top: 5%;
        left: 5%;
        width: max-content;
    }
    .divide{
        flex-wrap: wrap;
    }
    .personalInfoSection{
        width: 100%;
    }
    .rightSide{
        width: 100%;
    }
}

@media screen and (max-width: 375px) {
    .contactus{
        font-family: 'Prompt', sans-serif;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: black;
        color: white;
        /* / padding: 0 20vh 0 20vh; / */
    }
    
    .contactusElements{
        width: 100%;
        /* / border: 1px solid red; / */
    }
    .banner{
        height: 200px;
    }
    .banner span{
        font-size: 1rem;
        top: 5%;
        left: 5%;
        width: max-content;
    }
}
@media screen and (max-width: 425px) {
    .banner{
        height: 150px;
    }
    .banner span{
        font-size: 1rem;
        top: 5%;
        left: 5%;
        width: max-content;
    }
}

@media screen and (max-width: 787px) {

    .contactus{
        font-family: 'Prompt', sans-serif;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: black;
        color: white;
        padding: 80px;
        /* / padding: 0 20vh 0 20vh; / */
    }
    
    .contactusElements{
        width: 100%;
        /* / border: 1px solid red; / */
    }
    .divide{
        display: block;
    }
    
    .personalInfoSection{
        width: 100%;
        margin-bottom: 1rem;
        padding: 1rem;
    }
    .rightSide{
        width: 100%;
        padding: 1rem;
    }
    .cInfo{
        gap: 0;
    }
    .cInfo div p{
        padding: .5rem;
        font-size: .8rem;
    }
    .split{
        flex-direction: column;
        gap: 1rem;
    }
}
