/* @font-face {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended';
    src: url('../../assets/font/Helvetica_Neue_LT_Pro_63_Medium_Extended.woff') format('woff');
  } */
/* .secondaryColor,
.prefixed,
.headerFormat,
.lightText {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended', sans-serif;
  } */





:root {
    --secondary-color: #0d0101;
    --text-color: #EBEBEB;
    --text-color-secondary: #aaaaaa;
}

.prefixed {
    width: 100%;
}

.headerText {
    font-size: 12px;
    font-weight: 300;
}
.extraTinyText{
    font-size: 8px;
}
.tinyText{
    font-size: 10px;
}
.extraSmallText{
    font-size: 12px;
}
.smallText{
    font-size: 12px;
    
}
.smText{
    width: 100%;
    height: 40px;
    outline: none;
    font-size: 1px;
    padding: 0 0 0 1rem;
    border: 1.5px solid var(--text-color-secondary);
    border-radius: 0px;
    background-color:#1A1A1A;
    color: white;
}
#newValue{
    color: #fff;
}
/* profile.module.css */
.sm input{
    background-color: #1A1A1A;
  }
.mediumText{
    font-size: 18px;
}
.bigText{
    font-size: 18px;
}
.b500{
    font-weight: 300;
}
.paragraphText {
    font-size: 10px;
    font-weight: 300;
}

.descFormat {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 0 1rem;
}

.descFormat2 {
    display: flex;
    flex-direction: column;
    gap: .2rem;
}

.headerFormat {
    font-size: 1rem;
    font-weight: bolder;
    padding: 0 0rem;
    display: flex;
    justify-content: space-between;
}

.lightText {
    font-weight: 300;
}

.secondaryColor {
    color: white;
}
.split{
    display: flex;
    gap: 2rem;
}
.uploadBtn2{
    border: 1px dashed gray;
    width: max-content;
    padding: 1rem 1.5rem;
}
.primaryBtn {
    width: 100%;
    background-color: transparent;
    border: 1px solid var(--text-color-secondary);
    color: var(--text-color);
    font-weight: 300;
    height: 40px;
    transition: all .3s;
}

.primaryBtn:hover {
    transition: all .3s;
    background-color: var(--secondary-color);
    border: 1px solid transparent;
}

.primaryBtnFilled {
    width: 100%;
    background-color: #0d0101;
    border: 1px solid transparent;
    color: var(--text-color);
    font-weight: 300;
    height: 40px;
    border-radius: 0.500rem;
    transition: all .3s;
}
.continueBtnFilled {
    width: 100%;
    background-color: #3D3C3C;
    border: 1px solid transparent;
    color: var(--text-color);
    font-weight: 300;
    height: 40px;
    border-radius: 0.500rem;
    transition: all .3s;
    border-color:"#110807";
}
.continueBtnFilled:hover{
    color: #0d0101;
}
.primaryBtnRed {
    width: 100%;
    background-color: black;
    border: 1px solid var(--secondary-color);
    color: var(--text-color);
    font-weight: 300;
    height: 40px;
    transition: all .3s;
}
.bold{
    font-weight: 300;
}
.bolder{
    font-weight: 300;
}
.center{
    width: 100%;
    text-align: center;
}
.end{
    width: 100%;
    text-align: end;
}
.appear{
    animation: 1s ease-in 0s 1 appear;
}
.note{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 10rem;
    background-color: #1A1A1A;
}

@keyframes appear {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.btn_read_more{
    border: none;
    outline: none;
    background-color: none;
    color: #fff;
    transition: 1s;
}
.btn_read_more:hover{
    color: #E6483D;
}

.disappear{
    animation: 1s ease-in 0s 1 appear;
}
@keyframes appear {
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}

.slideInLeft{
    animation: .3s ease-in-out 0s 1 slideInLeft;
}
@keyframes slideInLeft {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

.slideOutLeft{
    animation: .3s ease-in-out 0s 1 slideOutLeft;
}
  @keyframes slideOutLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
  @media only screen and (max-width: 767px) 
{
    .terms{
        padding: 10px !important;
    
    }
    .prefixed{
        padding-bottom: .5rem;
    }
   
}