.payment-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
  }
  .payment-header {
    text-align: center;
  }
.customer-details-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}  