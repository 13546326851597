/* @font-face {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended';
    src: url('../../../assets/font/Helvetica_Neue_LT_Pro_63_Medium_Extended.woff') format('woff');
  }

  .mainSection {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended', sans-serif;
  } */
:root {
    --secondary-color: #E6483D;
    --text-color: #EBEBEB;
    --text-color-secondary: #aaaaaa;
    --color3: #1A1A1A;
}

.mainSection{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    /* border: 5px solid red; */
}
.modalSection{
    background-color: black;
    width: 420px;
    height: max-content;
    position: relative;
    display: flex;
    flex-direction: column;
    /* border: 1px solid pink; */
}
.topSection{
    border: 1px solid var(--secondary-color);
}
.closeBtn{
    width: 50px;
    height: 50px;
    cursor: pointer;
}
.modalHeading{
    height: 55px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    font-size: 18px;
    font-weight: 300;
    background-color: rgb(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
}
.modalLandingImage{
    width: 100%;
    height: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.modalLandingImage img{
    width: 480px;
    height: 170px;
    /* border: 2px solid yellow; */
}
.bottomSection{
    background-color: rgb(0, 0, 0, 0.5);
    width: 100%;
    color: var(--text-color-secondary);
    border: 1px solid black;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-sizing: border-box;
}
.carInfoDetails{
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nameInfo{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.dateTimeSection{
    /* width: 100%; */
    display: grid;
    grid-template-columns: repeat(2,50%);
    justify-content: space-evenly;
    column-gap: .5rem;
    row-gap: .5rem;
}
.dateTimeSection input{
    width: 100%;
    height: 40px;
    outline: none;
    font-size: 14px;
    padding: 0 0 0 1rem;
    border: 1.5px solid var(--text-color-secondary);
    background-color: #1A1A1A;
    color: white;
}
.dateTimeSection :placeholder-shown{
    font-size: 10px;
  }
.smText{
    width: 100%;
    height: 40px;
    outline: none;
    font-size: 1px;
    padding: 0 0 0 1rem;
    border: 1.5px solid var(--text-color-secondary);
    border-radius: 0px;
    background-color: #202020;
    color: white;
}

#newValue{
    color: #fff;
}
.btnSection{
    /* margin-top: 2rem; */
    width: 100%;
}
.slideInTop{
    animation: .3s ease-in-out 0s 1 slideInTop;
}
.slideOutTop{
    animation: .3s ease-in-out 0s 1 slideOutTop;
}
.dates{
    font-size: 12px;
}

@keyframes slideInTop {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
@keyframes slideOutTop {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100%);
    }
  }
 