/* ThankYou.css */
.container {
    text-align: center;
    padding: 50px;
    font-family: Arial, sans-serif;
  }
  
  .title {
    font-size: 2em;
    color: black;
  }
  
  .message {
    font-size: 1.2em;
    color: #666;
  }
  
  .highlight {
    color: white;
  }
  
  .checkmark {
    width: 100px;
    height: 100px;
    margin: 20px 0;
  }
  
  .checkEmail {
    font-size: 1.5em;
    color: black;
  }
  
  .contact {
    font-size: 1em;
    color: #666;
  }
  
  .contact a {
    color: black;
  }
  
  /* Mobile responsive styles */
  @media (max-width: 600px) {
    .container {
      padding: 16%;
    }
  
    .title {
      font-size: 1.5em;
    }
  
    .message {
      font-size: 1em;
    }
  
    .checkmark {
      width: 80px;
      height: 80px;
    }
  
    .checkEmail {
      font-size: 1.2em;
    }
  
    .contact {
      font-size: 0.9em;
    }
  }
  