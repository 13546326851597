@keyframes appear {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
.logingSection{
    max-width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: 1s ease-in 0s 1 appear;
}
.container{
width: 420px;
min-height: fit-content ;
background-color: #1a1a1a;
color: white;
display: flex;
flex-direction: column;
flex-wrap: wrap;
gap: 1rem;
border-radius: 0.500rem;
align-items: flex-start;
padding: 1rem;
/* margin: auto auto; */
}

.phoneInput{
    display: flex;
    flex-direction: column;
    background-color: #1a1a1a;
    gap: .5rem;
    width: 100%;
}
.inputs{
    width: 100%;
    display: flex;
    gap: 1rem;
}
.input1, .input2{
    height: 40px;
    outline: none;
    border-radius: 0.500rem;
    background-color: #1a1a1a;
    color:white;
    border: 1px solid black;
}
.input1{
    max-width: 80px;
    display: flex;
    text-align: center;
}
.input2{
    padding: 0 .5rem;
    width: 100%;
}
.otpinputs{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.bottomSection{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.captchaContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px; /* Adjust as needed */
  }