/* @font-face {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended';
    src: url('./assets/font/Helvetica_Neue_LT_Pro_63_Medium_Extended.woff') format('woff');
  } */

  /* .pages {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended', sans-serif;
  } */

html, body {
    margin: 0;
    padding: 0;
}
body{
    overflow-x: hidden;
}

.floating-whatsapp-container .floating-whatsapp-avatar img {
    width: 20px !important; 
    height: 20px !important;
}

.app{
    background-color: #3D3C3C;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.pages{
    font-family: 'Prompt', sans-serif;
    width: 100%;
    padding: 0 20vh 0 20vh;
    /* background-color: #3D3C3C; */
    color: white;
    position: relative;
}
.scroll_button{
    position: fixed;
    bottom: 17%;
    right: 2.5rem;
    border: 2px solid white;
    background-color:black;
    width: 50px;
    height: 50px;
    font-size: 40px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    transition: all .3s;
}
.scroll_button img{
    width: 50px;
    height: 50px;
}

@media screen and (max-width: 1100px) {
    .pages{
        padding: 5px 5px;
    }
    .scroll_button{
       
        bottom: 13%;
        right: 9%;
       
       
    }
}
/* styles.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  @media screen and (max-width: 428px) {
    body {
        overflow-x: hidden;
    }
}
@media screen and (max-width: 375px) {
    body {
        overflow-x: hidden;
    }
}
