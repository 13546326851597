/* @font-face {
  font-family: 'Helvetica Neue LT Pro 63 Medium Extended';
  src: url('../../../assets/font/Helvetica_Neue_LT_Pro_63_Medium_Extended.woff') format('woff');
}

.aboutuscontainer {
  font-family: 'Helvetica Neue LT Pro 63 Medium Extended', sans-serif;
} */

.aboutuscontainer {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    font-size: .8rem;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  h2 {
    color: #fff;
    font-size: 18px;
  }
  
  p {
    line-height: 1.5;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  strong {
    font-weight: bolder;
  }
  @media only screen and (max-width: 768px) {
    .aboutuscontainer {
      padding: 1% 17%;
    }

  }

  
  /* Add more styles as needed */
  