@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* @font-face {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended';
    src: url('../../assets/font/Helvetica_Neue_LT_Pro_63_Medium_Extended.woff') format('woff');
  } */
:root {
    --secondary-color: #110807;
    --text-color: #EBEBEB;
    --text-color-secondary: #aaaaaa;
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.flexcontainer1 {
    border-top: 2px solid;
    

  }
.flexcontainer {
    display: flex;
    flex-wrap: wrap;
    gap: 0px; /* Adjust the gap as needed */
    border-bottom: 2px solid;
    /* padding-top: 3rem; */
    border-top: 2px solid;

  }
  
  .flexitem {
    flex: 1 1 33.3%; /* Allows for four items per row */
    max-width: 33.3%;
    box-sizing: border-box;
    padding: 0px;
    
    background-color: black;
    color: white;
  }
  .flexitem a:hover{
    color: #3D3C3C !important;

  }
  

/* .myprofile {
    display: flex;
} */
.name{
    /* display: none; */
  position: absolute;
  /* background-color: #f9f9f9; */
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content option {
    color: #3D3C3C;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }


/* .myprofile__BV_toggle_ {
    border: 0;
    font-family: TTCommons;
    font-size: 14px;
    padding: 0;
    text-transform: capitalize;
} */

.flag {
    display: menu;
}

/* Navbar */
.navbar {
    /* font-family: 'Helvetica Neue LT Pro 63 Medium Extended', 'Prompt', sans-serif; */
    width: 100%;
    height: 10vh;
    background-color: #3D3C3C;
    color: rgb(223, 223, 223);
    margin: 0 0 20px 0;
    padding: 0 20vh 0 20vh;
    display: flex;  
    align-items: center;
    font-weight: 300;
    /* animation: 1s ease-in 0s 1 appear; */
    /* border: 1px solid red; */
}

.navbar a {
    text-decoration: none;
    color: white;
    transition: all .3s;
}

.footer a {
    text-decoration: none;
    color: var(--text-color);
    transition: all .3s;
    font-size: small;

}

a:hover {
    color: var(--secondary-color) !important;
    transition: all .3s;
}

.navElements {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    /* border: 1px solid; */
}

.left {
    display: flex;
    align-items: center;
    gap: 2vw;
    font-size:.8rem
}

.right {
    display: flex;
    align-items: center;
    gap: 2vw;
    font-size: .8rem;

}

.middle {
    position: absolute;
    left: 40%;
    cursor: pointer;
}

.profileIcon {
    /* width: 24px; */
    /* height: 24px; */
    /* background-color: var(--secondary-color); */
    border-radius: 50%;
    cursor: pointer;
}

.btn {
    width: 9rem;
    height: 32px;
    border: none;
    position: relative;
    font-weight: 300;
    transition: all .3s;
}

.btn:hover {
    background-color: var(--secondary-color);
}

.location {
    position: relative;

}

.btnSelect {
    background-color: #3D3C3C;
    color: white;
    width: max-content;
    outline: none;
    padding: 0 10px 0 30px;
    height: 32px;
    border: none;
    position: relative;
    font-weight: 300;
    transition: all .3s;
}

.locationImg {
    width: 24px;
    height: 24px;
    background-image: url('../../assets/images/icons/icons8-location-64 (1).png');
    background-size: 100% 100%;
    display: inline;
    position: absolute;
    z-index: 1;
    left: 5%;
    top: 15%;
    transition: all .3s;
}

.btn:hover .locationImg {
    background-image: url('../../assets/images/icons/location-icon-light.png');
    background-size: 100% 100%;
    transition: all .3s;
}


/* Footer */
.footer {
    /* font-family: 'Helvetica Neue LT Pro 63 Medium Extended', 'Prompt', sans-serif; */
    font-weight: 300;
    background-color: black;
    /* height: 50vh; */
    width: 100%;
    padding: 0 20vh 5rem 20vh;
    margin-top: 0rem;
}

.fooElements {
    
    width: 100%;
    height: max-content;
    display: flex;
    padding-top: 3rem;
    justify-content: space-between;
    align-items: flex-start;
    /* border: 1px solid red; */
}

.section {
    width: 100%;
    /* border: 1px solid; */
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: top;
    font-size: .8rem;
    color: var(--text-color);
    gap: 1rem;
    /* border: 1px solid pink; */
}

.section span {
    font-size: 1.2rem;
    color: #3D3C3C;
    align-items: flex-start;
    font-weight: 300;
}

.section ul {
    font-weight: 300;
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .3rem;
    /* border: 1px solid; */
}

.section ul li {
    /* color: var(--text-color); */
    color: var(--text-color);
    margin: 0;
    padding: 0;
    font-size: 1rem;
    cursor: pointer;
    transition: all .3s;
}

    .section ul li:hover {
        color: var(--text-color-secondary);
        transition: all .3s;
    }

.logoSection img {
    width: 12vw;
    height: 8.8rem;
}

.logoSection {
    align-items: center;
    text-align: center;
    margin-top: -2%;
}
.logoSection .contact{
    font-size: 1rem;
    color: white;
    align-items: flex-start;
    font-weight: 300;
    cursor: pointer;
}


.menu {
    display: none;
}

.offcanvas{
    width: 25% !important;
}
@media only screen and (max-width: 500px) {
    .offcanvas
    {
    width: 60% !important;
    }
    }
@media only screen and (max-width: 500px) {
        div.offcanvas.offcanvas-end.bg-dark.show
        {
        width: 60% !important;
        }
        }
.mobileMenu {
    z-index: 10;
    position: fixed;
    top: 0;
    width: 76%;
    height: 100%;
    background-color: rgb(39, 39, 39);
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 11%;
    gap: 2rem;
    font-size: 24px;
}

.closeBtn {
    position: absolute;
    top: 0%;
    left: 85%;
    cursor: pointer;
    /* background-color: #3D3C3C; */
    }

.popularCities{
    cursor: pointer;
}

.slideInLeft {
    animation: .3s ease-in-out 0s 1 slideInLeft;
}

.slideOutLeft {
    animation: .3s ease-in-out 0s 1 slideOutLeft;
}

/* .location{
    animation: .3s ease-in-out 0s 1 appear;
} */
@keyframes appear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideInLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideOutLeft {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@media screen and (max-width: 1100px) {
    .flexcontainer {
        display: flex;
        flex-wrap: wrap;
        gap: 0px; /* Adjust the gap as needed */
        border-bottom: 2px solid;
        padding-top: 3rem;
        border-top: 2px solid;

      }
      
      .flexitem {
        flex: 1 1 25%; /* Allows for four items per row */
        max-width: 25%;
        box-sizing: border-box;
        padding: 0px;
        
        background-color: black;
        color: white;
      }
      .flexitem a:hover{
        color: #3D3C3C !important;
    
      }
    .navbar {
        font-family: 'Prompt', sans-serif;
        width: 100%;
        height: 10vh;
        background-color: #3D3C3C;
        color: rgb(223, 223, 223);
        margin: 0 0 20px 0;
        padding: 0 20vw;
    }

    .menu {
        display: block;
    }

    .hide {
        display: none;
    }

    .logo {
        position: static;
        cursor: pointer;
        margin-left: 10%;
    }

    a {
        text-decoration: none;
        color: var(--text-color-secondary);
    }

    .footer {
        padding: 5px 5px;
    }

    .logoSection img {
        width: 40%;
        height: 40%;
    }
}
@media screen and (max-width: 360px) {
    .flexcontainer {
        display: flex;
        flex-wrap: wrap;
        gap: 0px; /* Adjust the gap as needed */
        border-bottom: 2px solid;
        padding-top: 3rem;
        border-top: 2px solid;

      }
      
      .flexitem {
        flex: 1 1 25%; /* Allows for four items per row */
        max-width: 25%;
        box-sizing: border-box;
        padding: 0px;
        
        background-color: black;
        color: white;
      }
      .flexitem a:hover{
        color: #3D3C3C !important;
    
      }
    .navbar {
        font-family: 'Prompt', sans-serif;
        width: 100%;
        height: 10vh;
        background-color: #3D3C3C;
        color: rgb(223, 223, 223);
        margin: 0 0 20px 0;
        padding: 0 23vw;
    }

    .menu {
        display: block;
    }

    .hide {
        display: none;
    }

    .logo {
        position: static;
        cursor: pointer;
        margin-left: 10%;
    }

    a {
        text-decoration: none;
        color: var(--text-color-secondary);
    }

    .footer {
        padding: 5px 5px;
    }

    .logoSection img {
        width: 40%;
        height: 40%;
    }
}

@media screen and (max-width: 600px) {

    .fooElements {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 3rem;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }
.socialic{
    padding-left: 25% !important;
}
    .section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: top;
        font-size: 1rem;
        color: var(--text-color);
        gap: .5rem;
    }


    .logo {
        margin-left:-45%
        
        
    }

    .section span {
        font-size: 1.2rem;
        color: var(--secondary-color);
        align-items: center;
        font-weight: 300;
    }

    .section ul {
        font-weight: 300;
        width: 50%;
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: .3rem;
    }

    .logoSection img {
        width: 25%;
        height: 25%;
    }
    .flexcontainer {
        display: flex;
        flex-wrap: wrap;
        gap: 0px; /* Adjust the gap as needed */
        border-bottom: 2px solid;
        padding-top: 3rem;
        border-top: 2px solid;

      }
      
      .flexitem {
        flex: 1 1 25%; /* Allows for four items per row */
        max-width: 25%;
        box-sizing: border-box;
        padding: 0px;
        
        background-color: black;
        color: white;
      }
      .flexitem a:hover{
        color: #3D3C3C !important;
    
      }
}
@media screen and (max-width: 780px) {
    .flexcontainer {
        display: flex;
        flex-wrap: wrap;
        gap: 0px; /* Adjust the gap as needed */
        border-bottom: 2px solid;
        padding-top: 3rem;
        padding-left: 100px;
        border-top: 2px solid;

    }

    .flexitem {
        flex: 1 1 100%; /* Take full width */
        max-width: 100%; /* Ensure full width */
        box-sizing: border-box;
        padding: 0px;
        background-color: black;
        color: white;
    }

    .flexitem a:hover {
        color: #3D3C3C !important;
    }
}


