/* @font-face {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended';
    src: url('../../assets/font/Helvetica_Neue_LT_Pro_63_Medium_Extended.woff') format('woff');
  }

  .summaryMain {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended', sans-serif;
  } */
:root{
    --secondary-background:#1A1A1A;
}
.summaryMain{
    width: 100%;
    display: flex;
    gap: 5rem;
    justify-content: center;
}
.leftSide{
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.summarySection{
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
}
.summaryHeader{
    width: inherit;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}   
.carDetailsSection{
    border: 1px solid gray;
    width: inherit;
    min-height: 250px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
    padding: 2rem;
    /* flex-wrap: wrap; */
}
.carDetailsSection>img{
    width: 220px;
    max-height: 140px;
    transform: scale(120%);

}
.carDetailsSection>div{
    width: inherit;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.bigText{
    border-bottom: 1px solid gray;
}

.personalInfoSection{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.infoForm{
    background-color: var(--secondary-background);
    max-width: inherit;
    min-height: 240px;
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.infoForm input{
    width: 100%;
    height: 40px;
    padding: 0 1rem;
    background-color: black;
    color: gray;
    outline: none;
    border: 1px solid gray;
    font-size: 14px;
}
.infoForm textarea{
    width: 100%;
    padding: .5rem 1rem;
    background-color: black;
    color: gray;
    outline: none;
    border: 1px solid gray;
    font-size: 14px;
}
.split{
    display: flex;
    gap: 2rem;
}
.alert{
    display:block;
    gap: 2rem;
    text-align: center;
}
.reqDocuments{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.uploadSection{
    border: 1px solid gray;
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    justify-content: space-between;
    padding: .5rem;
}
.uploadSection div{
    display: flex;
    flex-direction: column;
}

input[type="file"] {
    display: none;
}
.fileUpload{
    background-color: var(--secondary-background);
    border: 1px dashed gray;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    padding: 1rem 1.5rem;
}
.paragraphSection{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.rightSide{
    border: 1px solid gray;
    width: 50%;
    height: max-content;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    /* gap: 2rem; */
}
.priceDetails{
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    align-items: flex-start;
    margin-top: 1rem;
}
.rightSideHeader{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: .5rem 1rem;
}
.priceDetails div{
    width: 100%;
    /* border-bottom: 1px solid gray; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 2rem;
}
.totalPrice{
    width: 100%;
    background-color: var(--secondary-background);
    min-height: 120px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    margin-top: 2rem;
}
.totalPrice div{
    width: 100%;
    display: flex;
}
/* .totalPrice div input{
    width: 100%;
    padding: 0 1rem;
    outline: none;
    border: 1px solid;
} */
#totalPriceInput{
    width: 100%;
    padding: 0 1rem;
    outline: none;
    border: 1px solid;
}
.totalPrice div button{
    width: 100px;
}
.total{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 3rem;
}
.readSection{
    display: flex;
    flex-direction: column;
    font-size: 10px;
    margin-top: 1rem;
    padding: 0 1rem;
}
.payButtonSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;
    gap: 1rem;
    padding: 0 1rem;
}
.payButtonSection span{
    display: flex;
    gap: .5rem;
    align-items: center;
}
.gstSection{
    display: flex;
}
@media screen and (max-width: 768px) {
    .infoForm :placeholder-shown{
        font-size: 11px;
      }
    .summaryMain {
        flex-direction: column;
    }
    
    .leftSide {
        width: 100%;
        padding: 89px;
        padding-top: 1px;
    }
    
    .rightSide {
        width: 100%;
        margin-top: -8rem;
        padding: 82px;
    }
    
    .carDetailsSection, .infoForm {
        padding: 1rem;
    }
    
    .carDetailsSection > img {
        width: 100%;
        max-height: none;
        transform: none;
        align-self: flex-start;
    }
    
    .personalInfoSection, .priceDetails, .totalPrice {
        margin-top: 1rem;
    }
    .uploadSection {
        flex-direction: column;
        align-items: flex-start;
         gap: 1rem;
         /* max-width: 150px; */
    }
   
}
@media screen and (max-width: 768px) {
    .carDetailsSection > img {
      width: 40%;
      height: auto;
      max-height: none;
      transform: none;
      align-self: flex-start;
    }
    .infoForm :placeholder-shown{
        font-size: 11px;
      }
  }
  @media screen and (max-width: 320px) {

    .uploadSection {
        flex-direction: column;
        align-items: flex-start;
        /* max-width: 150px; */
                 gap: 1rem;
        }
        .infoForm :placeholder-shown{
            font-size: 11px;
          }

  }
  .coupon{
    color: var(--secondary-color);
    font-size: 11px;
  }