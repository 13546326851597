.why-blck-luxury {
    text-align: center;
    font-family: 'Arial', sans-serif;
    margin: 50px auto;
    width: 80%;
    max-width: 900px;
    color: #666; /* Adjust the color to match the styling */
  }
  
  .why-blck-luxury h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: 400;
    color: #333;
  }
  
  .why-blck-luxury p {
    font-size: 1rem;
    
  }
  
  .why-blck-luxury p h4 {
    display: block;
    font-size: 1.25rem;
    margin-bottom: 10px;
    font-weight: 300;
    color: white;
  }
  
  /* Responsive Styling */
  @media (min-width: 200px) and (max-width: 600px) {
    .why-blck-luxury {
      
    }
  
    .why-blck-luxury h2 {
      font-size: 1.5rem;
    }
  
    .why-blck-luxury p {
      font-size: 1rem;
    }
  }
  