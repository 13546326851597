/* @font-face {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended';
    src: url('../../assets/font/Helvetica_Neue_LT_Pro_63_Medium_Extended.woff') format('woff');
  }

  .main {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended', sans-serif;
  } */

.main{
    width: 100%;
    background-color: black;
}
.header{
    /* background-image: url('../../assets/images/common/headerRectangle.png'); */
    background-size: 100% 100%;
    width: 100%;
    height: 78px;
    padding: 0 2rem;
    display: flex;
    align-items: center;
}
.header span{
    font-size: 21px;
}
.banner {
    width: 100%;
    height: 370px;
    background-image: url('../../assets/images/Group\ 7798.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    margin-bottom: 3rem;
}
.content{
    padding: 0 2rem;
    font-size: 14px;
    text-align: justify;
}
.content section{
    margin: 2rem 0;
}
.subHeader{
    font-size: 18px;
}
.subHeaderBig{
    font-size: 22px;
}
section ol {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
section ol li{
    font-size: 14px;
    list-style-type: decimal;
}
@media only screen and (max-width: 768px) {
    .main{
        width: 90%;
        padding-left: 46px;
    }
}