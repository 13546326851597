@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marcellus&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Helvetica+Neue+LT+Pro:wght@400;700&display=swap');
/* @font-face {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended';
    src: url('../../assets/font/Helvetica_Neue_LT_Pro_63_Medium_Extended.woff') format('woff');
  }

  .homepage {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended', sans-serif;
  } */
  /* @font-face {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended';
    src: url('../../assets/font/Helvetica_Neue_LT_Pro_63_Medium_Extended.woff') format('woff');
  }

  .tabSection,
  .filter,
  .mobilefilter,
  .carDetails,
  .card
  .homepage {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended', sans-serif;
  }  */
:root {
    --secondary-color: #ffffff;
    --text-color: #EBEBEB;
    --text-color-secondary: #aaaaaa;
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes scaledAppear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.homepage {
    /* font-family: 'Helvetica Neue LT Pro', sans-serif; */
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: #3D3C3C; */
    background:transparent;
    color: white;
}

.homepageElements {
    min-width: 100%;
}

/* Other styles remain unchanged */


.reactCarousel div{
    background-color: #3D3C3C;
    border: none;
    outline: none;
    width: 100%;
}
.banner {
    width: 100%;
    height: 370px;
    background-color: #3D3C3C;
    background-image: url('../../assets/images/banners/Group\ 7795.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    /* transform: scale(99%); */
}
.bannerSpan {
    width: 20vw;
    position: absolute;
    top: 5rem;
    left: 5rem;
    font-size: 3.25rem;
    line-height: 3rem;
    font-weight: 300;
}
.banner1{
    background-color: #3D3C3C;
    width: 100%;
    height: 370px;
    background-image: url('../../assets/images/banners/Group\ 7802.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    transform: scale(99%);
    /* background-image: url(../../assets/images/yachts/Group\ 3666.png); */
}
.banner2{
    background-color: #3D3C3C;
    width: 100%;
    height: 370px;
    background-image: url('../../assets/images/banners/Group\ 7790.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    transform: scale(99%);
}

.mobilefilter{
    display:none;
}


/* .filter {
   
        font-size: 12px;
        background-color: transparent;
        border: none;
        border-radius: 5px;
        position:absolute;
        background-color: #262525;
        color: white;
        right: 150px;
        width: 116px;
        height: 30px;
        color: var(--text-color);
        font-weight: 300;
        transition: all .3s;
        background-color: var(--secondary-color);
        
      
} */
.filter:hover {
    /* transition: all .3s; */
    /* border: 1px solid white; */
}




.filter img {
    /* cursor: pointer; */
}

.tabSection {
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    gap: 3rem;
    transition: all .3s;
    margin-top: 1rem;
    /* border: 1px solid yellow; */
}

.tab {
    color: var(--text-color-secondary);
    font-size: .8rem;
    font-weight: 300;
    border: 3px solid transparent;
    transition: all .3s;
    cursor: pointer;
    padding: 0 .5rem 0 .5rem;
}

.tab:hover {
    color: var(--text-color);
    border-bottom: 3px solid var(--secondary-color);
    transition: all .3s;
}

.selected {
    color: var(--text-color);
    border-bottom: 3px solid var(--secondary-color);
    transition: all .3s;
}

.bodySection {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 32.6%);
    row-gap: 1rem;
    column-gap: 1rem;
    animation: 1s ease-in 0s 1 appear;
    /* align-items: center; */
    /* justify-content: space-between; */
    /* border: 1px solid blue; */
}

.card {
    cursor: pointer;
    width: 100%;
     height:100%; 
    /* background-color: #3D3C3C; */
     display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    padding: 0 1rem;
    transition: all .3s;
    position: relative;
    animation: .1s ease-in 0s 1 scaledAppear;
    border-radius: 5px;
    /* border: 1px solid green; */
}

.card:hover {
    transition: all .3s;
    background-image: linear-gradient(to bottom, #363636 0%, #262525 100%);
}

.card img {
    width: 100%;
    height: 160px;
    border-radius: 5px;
}

.card:hover img {
    transform: scale(102%);
    transition: .5s;
}

.carDetails {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* font-size: 1rem; */
    font-weight: 300;
    margin-bottom: .5rem;
    /* padding: 0 2rem 0 2rem; */
}

.carName {
    /* font-size: clamp(12px, 14px, 18px); */
    font-size: .8rem;

}

.rentDetails {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: .7rem;

    /* justify-content: space-between; */
}
.flexcontainer1 {
    border-top: 2px solid;
    

  }
.rentDetailsPrice {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
}

.carRentPrice {
    /* font-size: 14px; */
    color: 'white';
    font-weight: 300;
}

.carRange {
    /* font-size: 12px; */
    font-weight: 300;
    color: var(--text-color-secondary);
    padding-bottom: 16px;
}

.bookNowBtn {
    font-size: 12px;
    width: 116px;
    height: 30px;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    color: var(--text-color);
    font-weight: 300;
    transition: all .3s;
    background-color: var(--secondary-color);
}

.bookNowBtn:hover {
    transition: all .3s;
    border: 1px solid white;
    background-color: #110807;
}

.soldout {
    position: absolute;
    background-color: var(--secondary-color);
    top: 0;
    left: 0;
    font-weight: 300;
    padding: .2rem;
}

/* Filter */
.filterWindow {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    top: 0;
    right: 0;
    padding: 1rem;
    z-index: 1;
    display: none;
    /* border: 2px solid red; */
    /* box-shadow: 0 0 10px 0 var(--secondary-color); */
}

.filterElements {
    /* animation: .3s ease-in-out 0s 1 slideInLeft; */
    background-color: #3D3C3C;
    position: fixed;
    width: 450px;
    height: 100vh;
    overflow-y: scroll;
    top: 0;
    right: 0;
    padding: 6rem 1rem 1rem 1rem;
    z-index: 1;
    transition: all .3s;
}

.filterElements::-webkit-scrollbar {
    display: none;
}

.filterWindow::-webkit-scrollbar {
    display: none;
}

.filterHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: .5rem;
    font-size: 1.2rem;
    width: inherit;
    background-color: #3D3C3C;
    position: fixed;
    top: 0;
    right: 0;
    padding: 1.5rem 1rem 1.5rem 1rem;
    z-index: 3;
    /* gap: 1rem; */
}

.filterElement {
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
}

.filterSortBy label {
    display: flex;
    align-items: center;
}

.checkbox {
    display: flex;
    gap: .5rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid var(--text-color-secondary);
}

.checkbox span {
    font-size: 14px;
    color: var(--text-color-secondary);
}

.filterSortBy label input {
    visibility: hidden;
    position: absolute;
    /* display: block; */
    /* height: 0; */
    /* width: 0; */
    /* overflow: hidden; */
}

.filterSortBy label span {
    height: 22px;
    width: 22px;
    border: 2px solid var(--secondary-color);
    display: inline-block;
}

[type=radio]:checked+span {
    background: var(--secondary-color);
}

.filterHeaderText {
    font-size: 1.2rem;
    font-weight: 300;
}

.filterPriceRange {
    width: 100%;
    position: relative;
}

.slidecontainer {
    width: 100%;
}

.slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 1rem;
    background: var(--text-color);
    -webkit-transition: .2s;
    transition: .2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    background: var(--text-color);
    border: 3px solid var(--secondary-color);
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 24px;
    height: 24px;
    background: var(--text-color);
    border: 3px solid var(--secondary-color);
    cursor: pointer;
}

.priceRangeValue {
    width: 100%;
    color: var(--secondary-color);
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.carStyle {
    width: 100%;
}

.carTypeList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: inherit;
    /* border: 1px solid; */
}  

.carTypeList div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
}

.carTypeList div img {
    width: 114px;
    height: 77px;
}

.carTypeList div span {
    font-size: .8rem;
    font-weight: 300;
}

.carBrands {
    margin-bottom: 6rem;
}

.results {
    width: inherit;
    background-color: #3D3C3C;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 1.5rem 1rem 1.5rem 1rem;
    border-top: 1px solid var(--text-color-secondary);
    display: flex;
    gap: 1rem;
}

.resultBtns {
    width: 50%;
    background-color: transparent;
    border: 1px solid var(--text-color-secondary);
    color: var(--text-color);
    font-weight: 300;
    height: 50px;
    font-weight: 300;
    transition: all .3s;
}

.resultBtns:hover {
    transition: all .3s;
    background-color: var(--secondary-color);
    border: 1px solid transparent;
}

.slideInLeft {
    animation: .3s ease-in-out 0s 1 slideInLeft;
}

.slideOutLeft {
    animation: .3s ease-in-out 0s 1 slideOutLeft;
}

@keyframes slideInLeft {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideOutLeft {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

@media only screen and (min-width: 1600px) {

    .card {
        width: 100%;
        height: 230px;
        /* border: 1px solid green; */
    }

    .card img {
        width: 90%;
        height: 160px;
        border-radius: 5px;
    }

    .banner,.banner1,.banner2{
        height: 400px;
    }
    
}
@media only screen and (min-width: 1800px) {

    .card {
        width: 100%;
        height: 250px;
    }

    .card img {
        width: 90%;
        height: 180px;
        border-radius: 5px;
    }

    .banner,.banner1,.banner2{
        height: 470px;
    }
}

@media only screen and (min-width: 1900px) {

    .card {
        width: 100%;
        height: 25vh;
    }

    .card img {
        width: 90%;
        height: 18vh;
        border-radius: 5px;
    }
}


@media screen and (max-width: 1100px) {
    .bannerSpan {
        font-size: 2.25rem;
        line-height: 2.25rem;
    }
    .banner,.banner1, .banner2 {
        height: 370px;
    }
    .bookNowBtn {
        font-size: 10px;
    }
    .bodySection {
        grid-template-columns: repeat(2, 49%);
    }
    .tab {
        font-size: .8rem;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content:center ;
    }
    .tabSection{
        width: 100%;
        height: 3rem;
        display: flex;
        align-items: center;
        gap: 3rem;
        transition: all .3s;
        margin-top: 1rem;
    }
    .card {
        width: 100%;
        height: 280px;
    }
    .card img {
        width: 90%;
        height: 230px;
    }
    
}

@media screen and (max-width: 880px) {
    .bannerSpan {
        font-size: 2rem;
        line-height: 2rem;
    }
    .banner,.banner1, .banner2 {
        height: 300px;
    }
    .card {
        width: 100%;
        height: 250px;
    }
    .card img {
        width: 90%;
        height: 180px;
    }
}

@media screen and (max-width: 780px) {
    .homepageElements {
        min-width: 100%;
        padding: 68px;
        padding-top: 0px;
    }
    .bannerSpan {
        font-size: 1.5rem;
        line-height: 2rem;
        position: absolute;
        top: 1rem;
        left: 1rem;
        font-weight: 300;
    }
    .banner,.banner1, .banner2 {
        height: 270px;
    }

    .tab {
        font-size: .8rem;
    }
    .tabSection{
        margin-top: .5rem;
    }
    .card {
        width: 100%;
        height: 250px;
    }
    .card img {
        width: 90%;
        height: 210px;
    }

    .filter{
        display:none
    }
    .mobilefilter{
       
        margin-left: 290px;
        font-size: 12px;
        /* background-color: #110807; */
        color: white;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        transition: all .3s;
        padding: .5rem;
        border: none;
        border-radius: 5px;
        font-weight: 300;
        width: 90px;
        padding-right: 22px;
        background: transparent;
        
    }
    .mobilefilter img{
        width: 35px;
    }

    
}

@media screen and (max-width: 680px) {
    .bannerSpan {
        font-size: 1.5rem;
        line-height: 1.8rem;
    }
    .banner,.banner1, .banner2 {
        height: 250px;
    }
    .tab {
        font-size: .8rem;
    }
    .card {
        width: 100%;
        height: 250px;
    }
    .card img {
        width: 90%;
        height: 180px;
    }
}

@media screen and (max-width: 600px) {
    .bannerSpan {
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
    .bodySection {
        grid-template-columns: repeat(1, 99%);
    }
    .banner,.banner1, .banner2 {
        height: 200px;
    }
    .tab {
        font-size: .8rem;
    }
    .card {
        width: 100%;
        height: 300px;
    }
    .card img {
        width: 90%;
        height: 250px;
    }
    .filterElements {
        background-color: #3D3C3C;
        position: fixed;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        top: 0;
        right: 0;
        padding: 6rem 1rem 1rem 1rem;
        z-index: 1;
        transition: all .3s;
    }

}
@media screen and (max-width: 500px) {
    .tabSection{
        justify-content: space-between;
    }
    .card {
        width: 100%;
        height: 250px;
    }
    .card img {
        width: 90%;
        height: 180px;
    }
    .tabSection {
        gap: 1rem;
        width: 90%;
        margin-left: 17px;
    }
    .tab {
        font-size: .8rem;
    }
}

@media screen and (max-width: 360px) {
    .homepageElements {
        min-width: 100%;
        padding: 68px;
        padding-top: 0px;
    }
    .card {
        width: 100%;
        height: 220px;
    }
    .card img {
        width: 90%;
        height: 210px;
    }
    .tab {
        font-size: .7rem;
    }
    .banner,.banner1, .banner2 {
        height: 150px;
    }
}

@media screen and (max-width: 320px) {
    .tab {
        font-size: .65rem;
    }
    .banner,.banner1, .banner2 {
        height: 120px;
    }
    .card {
        width: 100%;
        height: 180px;
    }
    .card img {
        width: 90%;
        height: 160px;
    }
}


/* .tabSection {
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    gap: 3rem;
    transition: all .3s;
    margin-top: 1rem;
}

.tab {
    color: var(--text-color-secondary);
    font-size: 1.3rem;
    font-weight: 300;
    border: 3px solid transparent;
    transition: all .3s;
    cursor: pointer;
    padding: 0 .5rem 0 .5rem;
} */

@media only screen and (max-width: 768px) {
    .mobileOnly {
        display: none;
    }
    .container {
        flex-direction: row;
      }
      .filter {
   
       margin-right: -140px;
        
      
}


.filter:hover {
    transition: all .3s;
    border: 1px solid white;
    background-color: #110807;
}

.filterElements {
    background-color: #3D3C3C;
    position: fixed;
    width: 60%;
    height: 100%;
    overflow-y: scroll;
    top: 0;
    right: 0;
    padding: 6rem 1rem 1rem 1rem;
    z-index: 1;
    transition: all .3s;
}
}
.container {
    display: flex;
    flex-direction: row;
  }

  .filter {
   
    font-size: 12px;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    position:absolute;
    background-color: #262525;
    color: white;
    right: 150px;
    width: 116px;
    height: 30px;
    color: var(--text-color);
    font-weight: 300;
    transition: all .3s;
    background-color: var(--secondary-color);
  
}


.filter:hover {
/* transition: all .3s; */
/* border: -1px solid white; */
background-color: #110807;
}