/* @font-face {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended';
    src: url('../../../assets/font/Helvetica_Neue_LT_Pro_63_Medium_Extended.woff') format('woff');
  }

  .feed_main {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended', sans-serif;
  } */

.feed_main{
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 20%;
    padding-right: 20%;
    background-color: black;
}
.feed_header{
    /* background-image: url('../../../assets/images/common/headerRectangle.png'); */
    background-size: 100% 100%;
    width: 100%;
    height: 78px;
    padding: 0 2rem;
    display: flex;
    align-items: center;
}
.banner {
    width: 100%;
    height: 370px;
    background-image: url('../../../assets/images/Group\ 7798.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    /* margin-bottom: 3rem; */
}
.feed_header span{
    font-size: 16px;
}
.feed_divide{
    display: flex;
    gap: 3rem;
}

.personalInfoSection{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 3px solid white;
    padding: 1rem;
}

.personalInfoSection{
    /* background-color: var(--secondary-background); */
    max-width: inherit;
    max-height: fit-content;
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.personalInfoSection input{
    width: 100%;
    height: 2.5rem;
    padding: 0 1rem;
    background-color: black;
    color: gray;
    outline: none;
    border: 1px solid gray;
    font-size: 12px;
}
.personalInfoSection select{
    width: 100%;
    height: 30px;
    padding: 0 1rem;
    background-color: black;
    color: gray;
    outline: none;
    border: 1px solid gray;
    font-size: 12px;
}
.personalInfoSection textarea{
    width: 100%;
    padding: .5rem 1rem;
    background-color: black;
    color: gray;
    outline: none;
    border: 1px solid gray;
    font-size: 12px;
}
.split, .split_division{
    display: flex;
    gap: 2rem;
}

.reqDocuments{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.uploadSection{
    border: 1px solid gray;
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    justify-content: space-between;
    padding: .5rem;
}
.uploadSection div{
    display: flex;
    flex-direction: column;
}

input[type="file"] {
    display: none;
}
.fileUpload{
    background-color: var(--secondary-background);
    border: 1px dashed gray;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    padding: 1rem 1.5rem;
}
.paragraphSection{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

/* Ratings */
.rate {
    float: left;
    /* height: 5rem; */
    padding: 0 10px;
    display: block;
    margin: auto;
}
.rate:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.rate:not(:checked) > label {
    float:right;
    font-size: 2.5rem !important;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:3rem;
    color:#ccc;
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    /* color: #ffc700;     */
    color: #E6483D;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    /* color: #deb217;   */
    color: #E6483D;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    /* color: #c59b08; */
    color: #E6483D;
}


/* For Use Ref messages */
.refdivision{
    display: flex;
    justify-content: space-between;
}
.tinyText{
    font-size: 10px;
    padding: 0 10px;
}
.secondaryColor{
    color: #E6483D;
}
.tinyTextRating{
    font-size: 10px;
    padding: 0 10px;
    text-align: center;
}
@media only screen and (max-width: 767px) 
{
.feed_main{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0% !important;
    padding-right: 0% !important;
    padding: 15% !important;
}
}