.partnership_main{
    width: 100%;
}
.partnerships_img_and_desc{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.partnerships_img_and_desc img{
    width: 100%;
    height: 30rem;
    padding: 1.5rem;
}
.partnerships_img_and_desc .partnership_desc p{
    font-size: 1rem;
}
.partnerships_img_and_desc .partnership_desc p a{
    color: #E6483D;
}