/* @font-face {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended';
    src: url('../../assets/font/Helvetica_Neue_LT_Pro_63_Medium_Extended.woff') format('woff');
  }

  .carHeader {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended', sans-serif;
  } */



:root {
    --secondary-color: #E6483D;
    --text-color: #EBEBEB;
    --text-color-secondary: #aaaaaa;
    --color3: #1A1A1A;
}

@keyframes appear {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.detailsPage {
    width: 100%;
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    justify-content: center;
    animation: 1s ease-in 0s 1 appear;
}

.leftSide {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    /* border: 1px solid red; */
    /* padding: 1rem; */
}

.carNote {
    background-color: #3D3C3C;
    color: black;
}

.detailsCarousel {
    width: inherit;
    height: 350px;
    /* border: 1px solid pink; */
}

.detailsCarousel div img {
    width: inherit;
    height: 350px;
}

.carouselMain {
    width: 100%;
    height: 100%;
}
/* .read_note_division{
    display: flex;
    justify-content: space-between;
} */
.menui{

    display: none;
}

.carTerms {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
   
}
.mobileMenu {
    z-index: 10;
    position: fixed;
    top: 0;
    width: 60%;
    height: 100%;
    background-color: rgb(39, 39, 39);
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    font-size: 24px;
}
.carTerms ol {
    padding: 0 1rem;
    margin: 0;
}

.rightSide {
    width: 100%;
    height: max-content;
    border: 1px solid var(--text-color-secondary);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
    align-items: center;
}
.feedbackCarousel{
    width: 100%;
    height: max-content;
    border: 1px solid var(--text-color-secondary);
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    margin-top: 20px;
    overflow: hidden;
    /* align-items: center; */
}
.feedback{
    position:relative;
    /* overflow: hidden; */
    text-align: center;
    width: 100%;
    height: 50%;

}


.chauffeurSection {
    text-align: center;
}

.infoSection {
    display: flex;
}
.carSpecs{
    flex-wrap: wrap;
}

.infoSection ul {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.packageSection {
    display: flex;
    flex-direction: column;
}

.selectSection {
    display: flex;
    justify-content: space-between;
    font-size:.8rem;
    gap: 1rem;
}

.depositSection {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.depositSection div {
    display: flex;
    flex-direction: column;
}
.descFormat2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.featuresSection {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

}
.featuresSection span{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 45px;
    height: 70px;
}
.driverBattaSection {
    height: 85px;
    font-size: .8rem;
    display: flex;
    background-color:  #0d0101;
    align-items: center;
    justify-content: space-around;
}

.driverBattaSection div {
    display: flex;
    flex-direction: column;
}

.selectDateSection {
    display: flex;
    font-size: .8rem;
    align-items: center;
    justify-content: center;
}

.selectDateBtn {
    width: 100%;
}

#customSelect {  
    width: 100%;
    height: 40px;
    background-color: white;
    background-image: url(../../assets/images/icons/down-arrow.svg);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 13px 8px;
    border:none;
    border-radius: 0.500rem;
    color: var(--color3);
    padding: 0 2rem 0 1rem;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    /* padding: 10px 30px 10px 10px; */
}
#customSelect::-ms-expand { display: none };

@media only screen and (min-width: 1600px) {
    .carTerms {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0px;
        width: 85%;
        margin-left: 30px;
    }
    .detailsCarousel {
        width: inherit;
        height: 400px;
    }
    
    .detailsCarousel div img {
        width: inherit;
        height: 400px;
    }

    .rightSide {
        width: 100%;
        height: max-content;
        border: 1px solid var(--text-color-secondary);
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 1rem;
        align-items: center;
    }
}
@media only screen and (min-width: 1800px) {
    .carTerms {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0px;
        width: 85%;
        margin-left: 30px;
    }
    .detailsCarousel {
        width: inherit;
        height: 400px;
    }
    
    .detailsCarousel div img {
        width: inherit;
        height: 400px;
    }

    .rightSide {
        width: 100%;
        height: max-content;
        border: 1px solid var(--text-color-secondary);
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 1rem;
        align-items: center;
    }
}
@media only screen and (min-width: 1900px) {
    .carTerms {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0px;
        width: 85%;
        margin-left: 30px;
    }
    .detailsCarousel {
        width: inherit;
        height: 450px;
    }
    
    .detailsCarousel div img {
        width: inherit;
        height: 450px;
    }

    .rightSide {
        width: 100%;
        height: max-content;
        border: 1px solid var(--text-color-secondary);
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 1rem;
        align-items: center;
    }
}
@media only screen and (max-width: 1200px) {
    .carTerms {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0px;
        width: 85%;
        margin-left: 30px;
    }
    .detailsPage {
        position: relative;
        left: 0;
        height: 100vh;
        top: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 1rem;
        justify-content: center;
        align-items: center;
        animation: 1s ease-in 0s 1 appear;
    }

    .detailsCarousel {
        width: inherit;
        height: 450px;
    }
    
    .detailsCarousel div img {
        width: inherit;
        height: 450px;
    }

    .rightSide {
        width: 100%;
        height: max-content;
        border: 1px solid var(--text-color-secondary);
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 1rem;
        align-items: center;
    }
}

@media screen and (max-width: 1100px) {
    .carTerms {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0px;
        width: 85%;
        margin-left: 30px;
    }
    .detailsCarousel {
        width: inherit;
        height: 400px;
    }
    
    .detailsCarousel div img {
        width: inherit;
        height: 380px;
    }
    
}
@media screen and (max-width: 1024px) {
    .carTerms {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0px;
        width: 85%;
        margin-left: 30px;
    }

    .detailsCarousel {
        width: 30%;
        height: 10%;
    }
    
    .detailsCarousel div img {
        width: 25%;
        height: 50%;
    }
      .menu{

    display: none;
}

   .carHeader {
         width: 75%;
        height: 50%;
       
    } 
.menui{

    padding-left: 90%;
    display: contents;
} 
       .rightSide {
        width: 100%;
        height: 65%;
        border: 1px solid var(--text-color-secondary);
        display: revert-layer;
        flex-direction: column;
        gap: 2rem;
        padding: 1rem;
        align-items: center;
    } 
}

@media screen and (max-width: 880px) {
    .carTerms {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0px;
        width: 85%;
        margin-left: 30px;
    }
    .detailsCarousel {
        width: inherit;
        height: 400px;
    }
    
    .detailsCarousel div img {
        width: inherit;
        height: 370px;
    }
    .rightSide {
        width: 100%;
        height: max-content;
        border: 1px solid var(--text-color-secondary);
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 1rem;
        align-items: center;
    }
}

@media screen and (max-width: 780px) {
    .carTerms {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0px;
        width: 85%;
        margin-left: 30px;
    }
    .detailsCarousel {
        width: inherit;
        height: 400px;
    }
    
    .detailsCarousel div img {
        width: inherit;
        height: 320px;
    }
  .selectDateSection {
    padding-top: 10px;
}
.driverBattaSection {
   
}
}

@media screen and (max-width: 680px) {
    .carTerms {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0px;
        width: 85%;
        margin-left: 30px;
    }
    .detailsCarousel {
        width: inherit;
        height: 400px;
    }
    
    .detailsCarousel div img {
        width: inherit;
        height: 295px;
    }
}
@media screen and (max-width: 624px) {
    .carTerms {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0px;
        width: 85%;
        margin-left: 30px;
    }
    .detailsPage{
     width: 100%;
     height: 50%;
    
     }
     /* .detailsCarousel {
        
         width: 10%;
         height: 10%;
     } */
     
     /* .detailsCarousel div img {
         width: 50px;
         height: 100px;
     } */
     
     .rightSide {
     
         width: 100%;
         height: max-content;
         border: 1px solid var(--text-color-secondary);
         display: flex;
         flex-direction: column;
         gap: 2rem;
         padding: 1rem;
         align-items: center;
         
     }
     
     
     /* .carTerms {
         display: flex;
         flex-direction: column;
         justify-content: space-evenly;
         padding-bottom: 70%;
        
     }  */
 .menu{
 
     display: none;
 }
 .menui{
 
     padding-left: 90%;
     display: contents;
 }
 }
@media screen and (max-width: 600px) {
    .carTerms {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0px;
        width: 85%;
        margin-left: 30px;
    }
    .detailsCarousel {
        width: inherit;
        height: 300px;
    }
    
    .detailsCarousel div img {
        width: inherit;
        height: 260px;
    }

}
@media screen and (max-width: 500px) {
    .carTerms {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0px;
        width: 85%;
        margin-left: 30px;
    }
    .detailsCarousel {
        width: inherit;
        height: 240px;
    }
    
    .detailsCarousel div img {
        width: inherit;
        height: 100%;
    }
    .rightSide {
        width: 100%;
        height: max-content;
        border: 1px solid var(--text-color-secondary);
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 1rem;
        align-items: center;
    }
    .menu{

    display: none;
}
.menui{

    padding-left: 90%;
    display: contents;
}
}
@media screen and (max-width: 428px) {
    .carTerms {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0px;
        width: 85%;
        margin-left: 30px;
    }
    .detailsCarousel {
       
        width: 250px;
        height: 25px;
    }
    
    .detailsCarousel div img {
        width: 50px;
        height: 100px;
    }
    
    .rightSide {
    
        width: 100%;
        height: max-content;
        border: 1px solid var(--text-color-secondary);
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 1rem;
        align-items: center;
        
    }
    
    
    /* .carTerms {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding-bottom: 70%;
       
    }  */
.menu{

    display: none;
}
.menui{

    padding-left: 90%;
    display: contents;
}
}

@media screen and (max-width: 375px) {
    .carTerms {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0px;
        width: 85%;
        margin-left: 30px;
    }
    .detailsCarousel {
       
        width: 250px;
        height: 25px;
    }
    
    .detailsCarousel div img {
        width: 50px;
        height: 100px;
    }
    
    .rightSide {
    
        width: 100%;
        height: max-content;
        border: 1px solid var(--text-color-secondary);
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 1rem;
        align-items: center;
        
    }
    
    
    /* .carTerms {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding-bottom: 70%;
       
    }  */
.menu{

    display: none;
}
.menui{

    padding-left: 90%;
    display: contents;
}
}

@media screen and (max-width: 360px) {
    .carTerms {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0px;
        width: 85%;
        margin-left: 30px;
    }
    .detailsCarousel {
      /* padding-top: 10%; */
        width: 70%;
        height: 20%;
    }
    .rightSide {
    
        width: 100%;
        height: 60%;
        border: 1px solid var(--text-color-secondary);
        display: revert-layer;
        /* flex-direction: column;
        gap: 2rem; */
        padding-top: 10%;
        /* align-items: center; */
    }
    
    .detailsCarousel div img {
       
       width: inherit;
        height: min-content;
    }
    .carHeader {
        width: 100%;
        height: 30%;
        /* padding-top: 60%; */
        /* display:ruby; */
    } 
.menu{

    display: none;
}
.menui{

    padding: 0.001rem;
    display: contents;
}

}

@media screen and (max-width: 320px) {
    .carTerms {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0px;
        width: 85%;
        margin-left: 30px;
    }
    .detailsCarousel {
        width: inherit;
        height: 300px;
    }
    .carHeader {
        width: 69%;
        height: 30%;
        padding-top: 100%;
        /* display:ruby; */
    } 
    .detailsCarousel div img {
        width: inherit;
        height: 250px;
    }
    /* .rightSide {
        padding-top: 60%;
        width: 100%;
        height: max-content;
        border: 1px solid var(--text-color-secondary);
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 1rem;
        align-items:center;
    } */
}
@media screen and (max-width: 280px) {
    .carTerms {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0px;
        width: 85%;
        margin-left: 30px;
    }
    .detailsPage{
        width: 100%;
        height: 100%;
        padding-bottom: 10%;
        padding-top: 0%;
        margin-top: -100%;
    }
    .detailsCarousel {
        /* padding-top: 10%; */
        width: 100%;
        height: 50%;
    }
    .carHeader {
        width: 69%;
        height: 60%;
        /* padding-bottom:10%; */
        /* display:ruby; */
    } 
    .detailsCarousel div img {
        width: inherit;
        height: 180px;
    }
    .rightSide {
        width: 100%;
        height: 60%;
        border: 1px solid var(--text-color-secondary);
        /* padding-bottom:60%; */
        display: revert-layer;
        /* flex-direction: column;
        gap: 2rem;
        padding: 1rem; */
        /* align-items:center; */
    }
    .menu{

        display: none;
    }
    .menui{
    
        padding: 50%;
        display: contents;
    }
}
@media only screen and (max-width: 767px) 
{
    .carTerms {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0px;
        width: 73%;
        margin-left: 75px;
    }
    .feedbackCarousel{
        border: 1px solid;
        justify-content: center;
        margin-bottom: 0px;
        margin-right: 20px;
        margin-left: 20px;
        margin-top: 0;
        padding: 20px 10px 20px 10px;
        position: unset;
        right: 0;
        left: 21px;
        width: 89%;
        bottom: 0px;
    }
    .rightSide {
        border: 1px solid #aaa;
        border: 1px solid var(--text-color-secondary);
        display: revert-layer;
        height: 60%;
        padding-top: 10%;
        width: 100%;
        margin: 0px;
        margin-right: 25px;
        padding: 67px;
    }
    .carHeader {
        width: 69%;
        height: 30%;
        /* padding-top: 60%; */
        font-size: 20px;
        /* display:ruby; */
    } 
}