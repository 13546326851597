@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

/* @font-face {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended';
    src: url('../../assets/font/Helvetica_Neue_LT_Pro_63_Medium_Extended.woff') format('woff');
  }

  .yachts,
  .yachtElements {
    font-family: 'Helvetica Neue LT Pro 63 Medium Extended', sans-serif;
  } */
:root {
    --secondary-color: #E6483D;
    --text-color: #EBEBEB;
    --text-color-secondary: #aaaaaa;
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes scaledAppear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.yachts {
    font-family: 'Prompt';
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 0 20vh 0 20vh; */
    /* background-color: black; */
    color: white;
}
.yachtElements {
    width: 100%;
}
.backimg{
    width: 100%;
    height: 500px;
    background-image: url(../../assets/images/yachts/Yachts2.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
/* .backimg video{
    /* width: 100%;
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    background-size: cover;
} 
*/

/* /YachtMenu/ */

.cardsurface{
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, .5fr));
    grid-gap: 5rem;
    justify-content: space-around;
}

.card_container{
    margin: 25px auto 0;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-around;
    align-items: flex-start;
}

.card_container img {
    max-width: 100%;
    height: 300px;
  }
.card{
    background-color: #221f1f;
    padding: 30px;
    position: relative;
    flex-wrap: wrap;
    box-shadow: 0 0 5px rgba(75, 75, 75, 0.07);
    z-index: 1;
}
/* .card_body{
    display: inline-block;
} */
/* .number{
    margin-top: 15px;
} */
.designbody{
    display: flex;
    justify-content:space-between
}

.card_title{
    /* font-family: "Cormorant Garamond", serif; */
    font-family: 'Prompt',;
    font-size: 30px;
    font-weight:200;
    line-height: 60px;
    margin: 10px 0;
    text-transform: capitalize;
    /* color: black; */
}
.sharelogo{
    font-size: 30px;
    font-weight: 300;
    line-height: 60px;
    /* color: black; */
    margin: 26px 0;

}

.card_description{
    /* text-align: center; */
    display: inline-block;
    width: 100%;
    height: 10px;
    /* color:black; */
    margin: 0 1rem;
    font-size: smaller;
}
.cardinfo, .foody, .booksailing{
    display: flex;
    justify-content: space-between;
    margin: 0 2rem;
}


.cardinfo h4{
    /* color:black; */
    margin: 1.5rem 0;
    font-size: medium;
    font-weight:500;
}

.separate{
    display: flex;
    justify-content: space-between;
}

.proplelogo, .drinklogo, .anchorlogo{
    /* color:black; */
    margin: 1.5rem 0;
}
.sectiondivision{
margin-top: 1.8rem;
}
.food_and_drink, .sailing{
    /* color:black; */
    font-size: small;
    font-weight: 200;
    margin: 1rem 0;
}
.sectiondivision h3, .food_and_drink h3, .sailing h3 {
    font-size: small;
    font-weight: 300;
} 

.booknow{
    color: rgb(88, 88, 240);
    font-size:medium;
    font-weight: 300;
    margin: 1.5rem 0;
    cursor: pointer;
}


/* Yacht Form */

.modalbody{
    display:inline-block;
    margin: auto;
    width: 100%;
    height: auto;
    padding: 0.3rem 2rem;
}
.modalinput input{
    display:inline-block;
    margin: .5rem 0;
    width: 100%;
    height: 2.5rem;
}

.modaldatetime{
    /* display: flex;
    justify-content:space-between; */
    margin: .5rem 0;
}

.modaldatetime input{
    width: 100%;
    height: 2.5rem;
}
.modaldatetime p{
    font-size: medium;
    font-weight: 300;
    margin: .3rem 0;
}
.couponsection{
    display: flex;
    justify-content: space-between;
}
.couponsection input{
    width: 75%;
    height: 2.5rem;
}
.couponsection Button{
    width: 20%;
}
.totalprice{
    display: flex;
    justify-content: space-between;
    margin: 0.5rem 0;
}
.totalprice h3{
    font-size: large;
    font-weight: 300;
}
.readsection{
    display: flex;
    justify-content: space-between;
}
.checkboxsection{
    display: flex;
}
.checkboxsection a{
    text-decoration: none;
}

.verify{
    width: 100%;
}


@media only screen and (min-width: 1600px) {
    .backimg{
        height: 400px;
    }
    .cardsurface{
        grid-template-columns: repeat(auto-fit, minmax(200px, .5fr));
        grid-gap: 5rem;
    }
}
@media only screen and (min-width: 1800px) {

    .backimg{
        height: 470px;
    }
    .cardsurface{
        grid-template-columns: repeat(auto-fit, minmax(200px, .5fr));
        grid-gap: 5rem;
    }
}

@media only screen and (min-width: 1900px) {

    .cardsurface{
        grid-template-columns: repeat(auto-fit, minmax(200px, .5fr));
        grid-gap: 5rem;
    }
   
}


@media screen and (max-width: 1100px) {
    .backimg{
        height: 370px;
    }
    .cardsurface{
        grid-template-columns: repeat(auto-fit, minmax(200px, .5fr));
    }
    
}

@media screen and (max-width: 880px) {
    .backimg{
        height: 300px;
    }
    .cardsurface{
        grid-template-columns: repeat(auto-fit, minmax(200px, .5fr));
        
    }
}

@media screen and (max-width: 780px) {
    .backimg{
        height: 270px; 
    }
    .cardsurface{
        grid-template-columns: repeat(2, minmax(300px, 500px));
    }
}

@media screen and (max-width: 680px) {
    .backimg{
        height: 250px;
    }
    .cardsurface{
        grid-template-columns: repeat(1, minmax(300px, 500px));
    }
}

@media screen and (max-width: 600px) {
    .backimg{
        height: 200px;
    }
    .cardsurface{
        grid-template-columns: repeat(1, minmax(300px, 500px));
    }

}
@media screen and (max-width: 500px) {
    .cardsurface{
        grid-template-columns: repeat(1, minmax(300px, 500px));
    }
}

@media screen and (max-width: 375px) {
    .backimg{
        height: 150px;
    }
    .cardsurface{
        grid-template-columns: repeat(1, minmax(300px, 500px));
    }
}

@media screen and (max-width: 320px) {
    .backimg{
        height: 120px;
    }
    .cardsurface{
        grid-template-columns: repeat(1, minmax(300px, 500px));
    }
}


/* For Use Ref messages */
.tinyText{
    font-size: 10px;
}
.secondaryColor{
    color: var(--secondary-color);
}