.profileMain{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.profileBanner{
    width: 100%;
    min-height: 240px;
    background-image: url('../../assets/images/banners/profile-banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 5rem;
}
.profileImage{
    width: 100px;
    height: 100px;
    border: 3px solid gray;
    border-radius: 50%;
    position: relative;
    top: 3rem;
}
.tabs{
    height: 50px;
    display: flex;
    align-items: center;
    gap: 50px;
    justify-content: center;
    background-color: white;
    font-weight: 300;
}
.tab{
    width: 175px;
    height: 50px;
    border-bottom: 5px  solid transparent;
    padding: .5rem 1rem;
    color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
    cursor: pointer;
    /* border: 1px solid red; */
}
.tab:hover{
    color: red;
    /* border-bottom: 5px  solid red; */
    transition: .3s;
}
.selected{
    color: red;
    border-bottom: 5px  solid red;
    transition: .3s;
}
.tabLine{
    border: 1px solid gray;
    height: 35px;
}
.tabSelected{   
    border: 1px solid red;
    width: 50px;
}
.photoUpload{
    position: absolute;
    top: 70%;
    left: 70%;
}
.tabBody{
    background-color: #1A1A1A;
    width: 460px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin-top: 2rem;
}
.tabBody01{

    /* background-color: #1A1A1A; */
    width: 620px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin-top: 2rem;

}
.profileTabSection{
    width: 100%;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.inputs{
    display: flex;
    flex-direction: column;
}
.profileTabSection div label{
    font-size: 14px;
    color: gray;
}
.profileTabSection div input{
    height: 40px;
    border: 1px solid gray;
    background-color: black;
    outline: none;
    color: white;
    padding: 0 1rem;
}
.uploadSection{
    border: 1px solid gray;
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    justify-content: space-between;
    padding: .5rem;
}
.uploadSection div{
    display: flex;
    flex-direction: column;
}

input[type="file"] {
    display: none;
}
.fileUpload{
    background-color: var(--secondary-background);
    border: 1px dashed gray;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    padding: 1rem 1.5rem;
}