.stateImage{
  width: 100%;
  padding: 20%;
    padding-top: 5%;
    margin-bottom: -13%;
}
.feed_main ul{
  line-height: 1.3rem;
}
.feed_main span{
/* font-weight: 500; */
/* text-decoration: underline; */
font-size: 20px;
}
.feed_main img{
  width: 100%;
    padding: 14%;
    padding-top: 1%;
    margin-bottom: -6%;
    padding-left: 1px;

}
.feed_main1 {
  padding: 20px;
  background-color: #3d3c3c;
  border-radius: 8px;

}
.feed_main {
  padding: 132px;
  margin: 16px;
  background-color: white;
  color: black;
  border-radius: 8px;
  padding-top: 1px;
}
.feed_main h1{
  text-align: center;
 
    font-size: 40px;
    margin-bottom: 20px;
  
}

.feed_main h2{
  /* padding: 20px; */
  /* background-color: #3D3C3C; */
  color: black;
  font-size: 25px;
    margin-bottom: 20px;
}

.feed_main h3{

  color: black;
  font-size: 18px;
    margin-bottom: 20px;
}
.feed_main h4{
font-size: 20px;
}
.feed_title {
  text-align: center;
  margin-bottom: 20px;
  color: black;
  font-size: 2.5rem;
}

.feed_description {
  text-align: center;
  margin-bottom: 40px;
  color: #666;
}

.feed_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.feed_item {
  background-color: #1e1e1e;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  width: 30%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  font-size: .8rem;
}

.feed_image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.feed_content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: .8rem;
}

.feed_text {
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
}

.feed_date {
  color: #777;
  margin-bottom: 10px;
}

.feed_description {
  color: #bbb;
  margin-bottom: 20px;
  flex-grow: 1;
}

.read_more {
  display: inline-block;
  padding: 10px 20px;
  background-color: black;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: auto;
  align-self: flex-start;
}

.read_more:hover {
  background-color: black;
}
@media (max-width: 768px) {
  .feed_item {
    width: 100%;
  }

  .feed_list {
    flex-direction: column;
    align-items: center;
  }

  .feed_main {
    padding: 81px;
    background-color: white;
    border-radius: 8px;
    margin: 1px;

  }
  .feed_main h1 {
    margin-top: -33%;

  }
}