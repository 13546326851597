/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background: transparent;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    text-align: center;
    width: 40%;
  }
   /* Close button styles */
   .close-button {
    display: none;
  }
  /* Responsive styles */
  @media (max-width: 600px) {
    .modal-content {
      width: 100%;
    }
  
    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background:fixed;
      border: none;
      font-size: 30px;
      cursor: pointer;
      color: white;
      display: block;
    }
  }
  
  @media (max-width: 360px) {
    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background:fixed;
      border: none;
      font-size: 30px;
      cursor: pointer;
      color: white;
      display: block;
   
    }
  }