#reporting_time{
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  border: 1px solid #32383e;
  border-radius: 0.375rem;
  width: 100%;
  padding: 0.5rem 0.9rem;
  line-height: 1.5;
  appearance: none;
  color: #ced4da;
  border: 1px solid #ced4da;
  background-clip: padding-box;
  /* / border-color: #32383e; / */
  background-color: black;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
/* .formBackground{
    background-color:#212529;
   } */ 
  /* .formContainer{
    background-color: #212529;
  } */
/* .modalcontent{
  background-color: #212529;
} */
.modelbody{
background-color: #262626;
}
.modalheader{
background-color: #262626;
color: #ced4da;

}
.conentcontainer{
background-color: #262626;
}
.notecontent{
background-color: black;
color: #ced4da;
}
.modalfooter{
background-color: #262626;
}
.card{
background-color: #262626;
color: #ced4da;
}
.title{
color: #ced4da;
}
.cardbody{
background-color: #262626;
}
.formcontrol{
background-color: black;
color: #ced4da;
}
#reporting_date{
background-color: black;
color: #ced4da;
}
.sendbutton{
background-color: rgb(203, 45, 45);
}
.closeButton {
color: red;
/* Add any other custom styles */
}