.primaryBtnFilled {
    width: 100%;
    background-color: var(--secondary-color);
    border: 1px solid transparent;
    color: var(--text-color);
    font-weight: 300;
    height: 40px;
    transition: all .3s;
    padding-left: 140px;
}

.primaryBtnFilled01{
   
    width: 100%;
    background-color: rgb(203, 45, 45);
    border: 1px ;
    color: var(--text-color);
    font-weight: 300;
    height: 40px;
    transition: all .3s;

}
.primary{
  width: 90px;
  background-color: rgb(203, 45, 45);
  border: 1px;
  color: var(--text-color);
  font-weight: 300;
  height: 40px;
  transition: all .3s;
  align-items: center;
  gap: 50px;
  justify-content: center;
  font-weight: 300;
  /* padding-left: 40px; */
  flex-wrap: nowrap;
  align-content: space-between;


}
.tabLine{
    border: 1px solid gray;
    height: 35px;
    align-items: center;
}
.tabBody{
    background-color: #1A1A1A;
    /* width: 750px; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin-top: 2rem;
    text-align: center;
    padding-right: 100px;
}
.tabs{
    height: 50px;
    display: flex;
    align-items: center;
    gap: 50px;
    justify-content: center;
    background-color: white;
    font-weight: 300;
    /* width: 600px */
}
.name{
    padding-left: 90px;
    display: flex;
    align-items: center;
    padding-top: 5px;


}


/* The switch - the box around the slider */
.switch {
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 3.5em;
    height: 2em;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    width: 40px;
    height: 20px;
    position: absolute;
    cursor: pointer;
    inset: 0;
    background: white;
    border-radius: 90px;
    overflow: hidden;
    transition: all 0.4s cubic-bezier(0.215, 0.610, 0.355, 1);
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 13px;
    right: 0.3em;
    bottom: 0.3em;
    transform: translateX(150%);
    background-color: #59d102;
    border-radius: inherit;
    transition: all 0.4s cubic-bezier(0.215, 0.610, 0.355, 1);
  }
  
  .slider:after {
    position: absolute;
    content: "";
    height: 12px;
    width: 13px;
    left: 0.3em;
    bottom: 0.3em;
    background-color: #cccccc;
    border-radius: inherit;
    transition: all 0.4s cubic-bezier(0.215, 0.610, 0.355, 1);
  }
  
  .switch input:focus + .slider {
    box-shadow: 0 0 1px #59d102;
  }
  
  .switch input:checked + .slider:before {
    transform: translateY(0);
  }
  
  .switch input:checked + .slider::after {
    transform: translateX(-150%);
  }
  


